import { reactive } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { IOption, IOptionString } from '../models/AntDesign/IOption';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';
import { IPreferencia, IPreferenciaContratante } from '@/models/Entidades/Sistema/Preferencias/Preferencia';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import { IPropriedadeConsulta } from '../models/Consulta/PropriedadeConsulta';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { IItemGenerico } from '../models/IItemGenerico';
import { IItemConsultaRapida } from '../models/Consulta/IItemConsultaRapida';
import { ITelaOperacao } from '../models/ITelaOperacao';
import { IOrdenacao } from '../models/Consulta/IOrdenacao';
import { IFiltroGenericoAdicionado } from '../models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IColumn } from '../models/AntDesign/IColumn';

export interface ITelaBase {
  titulo: string;
  identificadorRecurso: string;
  identificadorPermissao: string;
  apresentarContratantes: boolean;
  contratantesDisponiveis: Array<IOption>;
  contratantesSelecionados: Array<number>;
  propriedadesConsulta: Array<IPropriedadeConsulta>;
  preferencias: Array<IPreferencia>;
  fetching: boolean;
  debounce: number;
  carregando: boolean;
  apresentarBarraProgresso: boolean;
  ordenacaoSelecionada: Array<IOrdenacao>;
}

export interface IRetornoTelaBase {
  telaBase: ITelaBase;
  obterContratantes(apenasAtivas: boolean): Promise<IOption[]>;
  preencherContratantesDisponiveis(): Promise<void>;
  verificaConceitoParaApresentarContratantes(): void;
  apresentarMensagemSucesso(mensagem: string): void;
  apresentarMensagemAlerta(mensagem: string): void;
  apresentarMensagemErro(mensagem: string): void;
  montaObjetoPreferencia(chave: string, valor: string, contratantes: number[]): IPreferencia;
  salvarPreferencias(mensagemSucesso: string, chave: string, valor: string, contratantes: number[]): Promise<void>;
  salvarPreferenciasFiltros(contratantes: number[], filtrosAdicionados: IFiltroGenericoAdicionado[]): Promise<void>;
  salvarPreferenciasOrdenacao(contratantes: number[], ordenacaoSelecionada: IOrdenacao[]): Promise<void>;
  salvarPreferenciasGrade(contratantes: number[], colunas: IColumn[]): Promise<void>;
  carregarPreferenciasFiltros(preferencias: IPreferencia[]): IFiltroGenericoAdicionado[];
  carregarPreferenciasOrdenacao(preferencias: IPreferencia[]): IOrdenacao[];
  carregarPreferenciasGrade(colunasPadrao: IColumn[], preferencias: IPreferencia[]): IColumn[];
  adicionarAtalho(identificadorRecurso: string): Promise<void>;
  removerAtalho(identificadorRecurso: string): Promise<void>;
  montaOpcoesComListaConsultaRapida(listaItens: IItemConsultaRapida[]): IOption[];
  montaOpcoesComListaConsultaRapidaInformacaoAdicional(listaItens: IItemConsultaRapida[]): IOptionString[];
  montaOpcoesComListaItemGenerico(listaItens: IItemGenerico[]): IOption[];
  montaOpcoesComListaItemGenericoTexto(listaItens: IItemGenerico[]): IOptionString[];
  telaOperacaoIncluir(): ITelaOperacao;
  telaOperacaoEditar(codigo: number): ITelaOperacao;
  preencherDadosRota(): void;
}

export function useTelaBase(): IRetornoTelaBase {
  const telaBase = reactive({
    titulo: '',
    identificadorRecurso: '',
    identificadorPermissao: '',
    apresentarContratantes: true,
    contratantesDisponiveis: [] as IOption[],
    contratantesSelecionados: [] as number[],
    propriedadesConsulta: [] as IPropriedadeConsulta[],
    preferencias: [] as IPreferencia[],
    fetching: false,
    debounce: 0,
    carregando: false,
    apresentarBarraProgresso: false,
    ordenacaoSelecionada: [] as IOrdenacao[],
  });

  async function obterContratantes(apenasAtivas: boolean): Promise<IOption[]> {
    let contratantes = await new ServicoSistema().obterDadosContratantes();
    let listaContratantes: IOption[] = [];
    if (apenasAtivas) {
      contratantes = contratantes.filter((c) => c.status === 1);
    }

    if (UtilitarioGeral.validaLista(contratantes)) {
      listaContratantes = contratantes.map((item) => ({
        label: UtilitarioGeral.montaExibicaoContratante(item.nomeRazaoSocial, item.nomeFantasia),
        value: item.codigoContratante,
      }));
    }

    return listaContratantes;
  }

  async function preencherContratantesDisponiveis(): Promise<void> {
    telaBase.contratantesDisponiveis = storeSistema.state.contratantesDisponiveis.map((item) => ({
      label: UtilitarioGeral.montaExibicaoContratante(item.nomeRazaoSocial, item.nomeFantasia),
      value: item.codigoContratante,
    }));
  }

  function verificaConceitoParaApresentarContratantes() {
    telaBase.apresentarContratantes = telaBase.contratantesDisponiveis.length > 1;
  }

  function apresentarMensagemSucesso(mensagem: string): void {
    message.success(mensagem);
  }

  function apresentarMensagemAlerta(mensagem: string): void {
    message.warning(mensagem);
  }

  function apresentarMensagemErro(mensagem: string): void {
    message.error(mensagem);
  }

  function montaOpcoesComListaConsultaRapida(listaItens: IItemConsultaRapida[]): IOption[] {
    let listaOpcoes: IOption[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.textoIdentificacao,
      value: Number(item.codigo),
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaConsultaRapidaInformacaoAdicional(listaItens: IItemConsultaRapida[]): IOptionString[] {
    let listaOpcoes: IOptionString[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.textoIdentificacao,
      value: item.informacaoAdicional,
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaItemGenerico(listaItens: IItemGenerico[]): IOption[] {
    let listaOpcoes: IOption[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.descricao,
      value: Number(item.identificador),
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaItemGenericoTexto(listaItens: IItemGenerico[]): IOptionString[] {
    let listaOpcoes: IOptionString[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.descricao,
      value: item.identificador,
    }));

    return listaOpcoes;
  }

  function montaObjetoPreferencia(chave: string, valor: string, contratantes: number[]): IPreferencia {
    const preferencia: IPreferencia = {
      codigo: 0,
      tipo: 0,
      identificadorRecurso: telaBase.identificadorRecurso,
      chave,
      valor,
      contratantes: [],
    };

    contratantes.forEach((contratante) => {
      const contratantePreferencia: IPreferenciaContratante = {
        codigo: 0,
        codigoPreferencia: 0,
        codigoContratante: contratante,
      };
      preferencia.contratantes.push(contratantePreferencia);
    });

    return preferencia;
  }
  async function salvarPreferencias(mensagemSucesso: string, chave: string, valor: string, contratantes: number[]) {
    const retorno = await new ServicoSistema().salvarPreferenciaUsuario(montaObjetoPreferencia(chave, valor, contratantes));
    if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
      if (mensagemSucesso !== '') {
        apresentarMensagemSucesso(mensagemSucesso);
      }
    } else {
      apresentarMensagemAlerta(retorno.mensagem);
    }
  }

  async function salvarPreferenciasFiltros(contratantes: number[], filtrosAdicionados: IFiltroGenericoAdicionado[]): Promise<void> {
    await salvarPreferencias('Filtros salvos com sucesso!', 'busca-avancada-filtros',
      JSON.stringify(filtrosAdicionados), contratantes);
  }

  async function salvarPreferenciasOrdenacao(contratantes: number[], ordenacaoSelecionada: IOrdenacao[]): Promise<void> {
    await salvarPreferencias('Ordenação salva com sucesso!', 'busca-avancada-ordenacao',
      JSON.stringify(ordenacaoSelecionada), contratantes);
  }

  async function salvarPreferenciasGrade(contratantes: number[], colunas: IColumn[]): Promise<void> {
    const colunasPersistencia: IColumn[] = []; let posicao = 0;
    colunas.forEach((coluna) => {
      const colunaNova = coluna;
      colunaNova.position = posicao;
      colunasPersistencia.push(colunaNova);
      posicao += 1;
    });
    await salvarPreferencias('Personalizações salvas com sucesso!', 'personalizacao-colunas',
      JSON.stringify(colunasPersistencia.sort((c) => c.position)), contratantes);
  }

  function carregarPreferenciasFiltros(preferencias: IPreferencia[]): IFiltroGenericoAdicionado[] {
    let filtrosAdicionados: IFiltroGenericoAdicionado[] = [];
    if (preferencias !== null) {
      const preferenciaFiltros = preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-filtros'));
      if (preferenciaFiltros !== undefined && preferenciaFiltros !== null) {
        filtrosAdicionados = JSON.parse(preferenciaFiltros.valor);
      }
    }

    return filtrosAdicionados;
  }

  function carregarPreferenciasOrdenacao(preferencias: IPreferencia[]): IOrdenacao[] {
    let ordenacaoSelecionada: IOrdenacao[] = [];
    if (preferencias !== null) {
      const preferenciaOrdenacao = preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-ordenacao'));
      if (preferenciaOrdenacao !== undefined && preferenciaOrdenacao !== null) {
        ordenacaoSelecionada = JSON.parse(preferenciaOrdenacao.valor);
      }
    }

    return ordenacaoSelecionada;
  }

  function carregarPreferenciasGrade(colunasPadrao: IColumn[], preferencias: IPreferencia[]): IColumn[] {
    if (preferencias !== null) {
      const preferenciaColunas = preferencias.find(((preferencia) => preferencia.chave === 'personalizacao-colunas'));
      if (preferenciaColunas !== undefined && preferenciaColunas !== null) {
        if (preferenciaColunas.valor !== '[]' && preferenciaColunas.valor !== '') {
          const colunasDB: IColumn[] = JSON.parse(preferenciaColunas.valor);
          const colunasNovasPreferencias: IColumn[] = [];
          colunasPadrao.forEach((coluna) => {
            const preferenciaCol = colunasDB.find(((colunaDB) => colunaDB.key === coluna.key));
            const colunaNova: IColumn = coluna;
            if (preferenciaCol !== undefined && preferenciaCol !== null) {
              colunaNova.visible = preferenciaCol.visible;
              colunaNova.fixed = preferenciaCol.fixed;
              colunaNova.align = preferenciaCol.align;
              colunaNova.width = preferenciaCol.width;
              colunaNova.position = preferenciaCol.position;
              colunasNovasPreferencias.push(colunaNova);
            }
          });
          return colunasNovasPreferencias.sort((x, y) => x.position - y.position);
        }
      }
    }
    return colunasPadrao;
  }

  async function adicionarAtalho(identificadorRecurso: string) {
    const retorno = await new ServicoSistema().adicionarAtalho(identificadorRecurso);
    if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
      apresentarMensagemSucesso(retorno.mensagem);
      const atalhos = await new ServicoSistema().obterMeusAtalhos();
      storeSistema.mutations.atualizarMeusAtalhos(atalhos);
    } else {
      apresentarMensagemAlerta(retorno.mensagem);
    }
  }

  async function removerAtalho(identificadorRecurso: string) {
    const retorno = await new ServicoSistema().removerAtalho(identificadorRecurso);
    if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
      apresentarMensagemSucesso(retorno.mensagem);
      const atalhos = await new ServicoSistema().obterMeusAtalhos();
      storeSistema.mutations.atualizarMeusAtalhos(atalhos);
    } else {
      apresentarMensagemAlerta(retorno.mensagem);
    }
  }

  function telaOperacaoIncluir(): ITelaOperacao {
    const telaOperacao = {} as ITelaOperacao;
    telaOperacao.codigoRegistro = 0;
    telaOperacao.contratanteSelecionado = telaBase.contratantesSelecionados[0];
    telaOperacao.codigoRegistroDuplicar = 0;
    telaOperacao.codigoRegistroPai = 0;
    return telaOperacao;
  }

  function telaOperacaoEditar(codigo: number): ITelaOperacao {
    const telaOperacao = {} as ITelaOperacao;
    telaOperacao.codigoRegistro = codigo;
    telaOperacao.contratanteSelecionado = telaBase.contratantesSelecionados[0];
    telaOperacao.codigoRegistroDuplicar = 0;
    telaOperacao.codigoRegistroPai = 0;
    return telaOperacao;
  }

  function preencherDadosRota(): void {
    const route = useRoute();
    telaBase.titulo = String(route.meta.titulo);
    telaBase.identificadorRecurso = String(route.meta.identificadorRecurso);
    telaBase.identificadorPermissao = String(route.meta.identificadorPermissao);
  }

  return {
    telaBase,
    obterContratantes,
    preencherContratantesDisponiveis,
    apresentarMensagemSucesso,
    apresentarMensagemAlerta,
    apresentarMensagemErro,
    montaObjetoPreferencia,
    salvarPreferencias,
    salvarPreferenciasFiltros,
    salvarPreferenciasOrdenacao,
    salvarPreferenciasGrade,
    carregarPreferenciasFiltros,
    carregarPreferenciasOrdenacao,
    carregarPreferenciasGrade,
    adicionarAtalho,
    removerAtalho,
    verificaConceitoParaApresentarContratantes,
    montaOpcoesComListaItemGenerico,
    montaOpcoesComListaItemGenericoTexto,
    montaOpcoesComListaConsultaRapida,
    montaOpcoesComListaConsultaRapidaInformacaoAdicional,
    telaOperacaoIncluir,
    telaOperacaoEditar,
    preencherDadosRota,
  };
}
