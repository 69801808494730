import dayjs from 'dayjs';
import { obterItem, removerItem, salvarItem } from '@/core/gerentes/GerenteLocalStorage';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';

export default class GerenteAutenticacao {
  private chaveTokenAutenticado = 'SS-NEOX-MKT-TOKEN';

  private chaveExpiracaoToken = 'SS-NEOX-MKT-TOKEN-EXPIRACAO';

  public salvarDadosAutenticacao(tokenAutenticacao: string, dataExpiracaoToken: string): void {
    salvarItem(this.chaveTokenAutenticado, tokenAutenticacao);
    salvarItem(this.chaveExpiracaoToken, dataExpiracaoToken);
  }

  public removerDadosAutenticacao(): void {
    removerItem(this.chaveTokenAutenticado);
    removerItem(this.chaveExpiracaoToken);
  }

  public existeToken(): boolean {
    const tokenAutenticado = obterItem(this.chaveTokenAutenticado);
    return UtilitarioGeral.valorValido(tokenAutenticado);
  }

  public existeValidadeToken(): boolean {
    const dataExpiracaoToken = obterItem(this.chaveExpiracaoToken);
    return UtilitarioGeral.valorValido(dataExpiracaoToken);
  }

  public obtemTokenAutenticado(): string {
    return `${obterItem(this.chaveTokenAutenticado)}`;
  }

  public tokenValido(): boolean {
    if (this.existeToken()) {
      return false;
    }
    return false;
  }

  public verificaAutenticacao(): boolean {
    if (!this.existeToken()) {
      return false;
    }

    if (!this.existeValidadeToken()) {
      return false;
    }

    return true;
  }

  public atualizarToken(): boolean {
    let dataExpiracaoToken = obterItem(this.chaveExpiracaoToken) as any;
    dataExpiracaoToken = dayjs(dataExpiracaoToken).format('YYYY-MM-DD HH:mm:ss');
    const dataAtual = dayjs().format('YYYY-MM-DD HH:mm:ss');

    if (dataAtual > dataExpiracaoToken) {
      return true;
    }
    return false;
  }
}
