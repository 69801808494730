import { IDTODadosContratante } from '@/models/DTO/Sistema/Contratantes/IDTODadosContratante';
import { IDTODadosUsuarioAutenticado } from '@/models/DTO/Sistema/Usuarios/IDTODadosUsuarioAutenticado';
import storeSistema from '@/store/storeSistema';
import ApiSistema from '@/core/conectores/ApiSistema';
import { IPreferencia } from '@/models/Entidades/Sistema/Preferencias/Preferencia';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDadosLogin } from '@/core/models/IDadosLogin';
import { IRetornoAutenticacao } from '@/core/models/IRetornoAutenticacao';
import { IDTOUsuarioRegistroAtividade } from '@/models/DTO/Sistema/Usuarios/IDTOUsuarioRegistroAtividade';
import { IDTOMeusAtalhos } from '@/models/DTO/Sistema/IDTOMeusAtalhos';
import { IDTOFiltroUsuarioRegistroAtividade } from '@/models/DTO/Sistema/Usuarios/IDTOFiltroUsuarioRegistroAtividade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IRecurso } from '@/models/Entidades/Sistema/IRecurso';
import { IMenu } from '@/models/Entidades/Sistema/IMenu';
import { ISalvamentoAutomatico } from '@/models/Entidades/Sistema/ISalvamentoAutomatico';
import { obterItem, salvarItem, removerItem } from '@/core/gerentes/GerenteLocalStorage';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IArquivo } from '@/models/Entidades/Sistema/IArquivo';
import { ERelacaoArquivo } from '@/models/Enumeradores/ERelacaoArquivo';

/**
 * Serviço do Sistema
 * Centraliza e organiza regras essências para o funcionamento do sistema, exemplo: Empresas,Módulos, Permissões, Licenças , Assinaturas e outros
 */

class ServicoSistema {
  private endPoint = 'sistema';

  private apiSistema = new ApiSistema();

  public async obterDataAtual(): Promise<string> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/data-atual`);
    return result.data;
  }

  public async login(dadosLogin: IDadosLogin): Promise<IRetornoAutenticacao> {
    const result: any = await this.apiSistema.post('autenticacao/login', dadosLogin);
    return result.data;
  }

  public async atualizarToken(): Promise<IRetornoAutenticacao> {
    const result: any = await this.apiSistema.post('autenticacao/atualizar-token');
    return result.data;
  }

  public async deslogar(): Promise<IRetornoAutenticacao> {
    const result: any = await this.apiSistema.post('autenticacao/logout');
    return result.data;
  }

  public async obterDadosContratantes(): Promise<IDTODadosContratante[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/dados-contratantes`);
    return result.data;
  }

  public async obterDadosUsuarioAutenticado(): Promise<IDTODadosUsuarioAutenticado> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/dados-usuario-autenticado`);
    return result.data;
  }

  public async salvarPreferenciaUsuario(objeto: IPreferencia): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/preferencias/usuario`, objeto);
    return result.data;
  }

  public async redefinirPreferenciaUsuario(objeto: IPreferencia): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/preferencias/usuario/redefinir`, objeto);
    return result.data;
  }

  public async obterPreferenciaRecurso(identificadorRecurso: string, empresas: Array<number>): Promise<IPreferencia[]> {
    const parametrosEmpresas = this.apiSistema.obterParametroContratantes(empresas);
    const result: any = await this.apiSistema.get(`${this.endPoint}/preferencias/recurso/${identificadorRecurso}${parametrosEmpresas}`);
    return result.data;
  }

  public async obterRecursos(valorPequisa: string, empresas: Array<number>, apenasMovimentoEstoque?: boolean): Promise<IRecurso[]> {
    let parametrosAdicionais = this.apiSistema.obterParametroContratantes(empresas);
    parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
    parametrosAdicionais += `valorPequisa=${valorPequisa}`;
    if (apenasMovimentoEstoque !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `apenasMovimentoEstoque=${apenasMovimentoEstoque}`;
    }

    const result: any = await this.apiSistema.get(`${this.endPoint}/recursos${parametrosAdicionais}`);
    return result.data;
  }

  public async obterTodosRecursos(): Promise<IRecurso[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/recursos/todos`);
    return result.data;
  }

  public async obterRegistroAtividadeRecurso(identificadorRecurso: string): Promise<IDTOUsuarioRegistroAtividade[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/registro-atividades/recurso/${identificadorRecurso}`);
    return result.data;
  }

  public async obterRegistroAtividade(filtroUsuarioRegistroAtividade: IDTOFiltroUsuarioRegistroAtividade): Promise<IDTOUsuarioRegistroAtividade[]> {
    let parametrosAdicionais = this.apiSistema.obterParametroContratantes(filtroUsuarioRegistroAtividade.empresas);

    if (filtroUsuarioRegistroAtividade.recursos !== undefined) {
      filtroUsuarioRegistroAtividade.recursos.forEach((item) => {
        parametrosAdicionais += `&Recurso=${item}`;
      });
    }

    if (UtilitarioGeral.validaLista(filtroUsuarioRegistroAtividade.usuarios)) {
      filtroUsuarioRegistroAtividade.usuarios.forEach((codigoUsuario) => {
        parametrosAdicionais += `&Usuarios=${codigoUsuario}`;
      });
    }
    if (UtilitarioGeral.valorValido(filtroUsuarioRegistroAtividade.detalhe)) {
      parametrosAdicionais += `&Detalhe=${filtroUsuarioRegistroAtividade.detalhe}`;
    }

    if (UtilitarioGeral.valorValido(filtroUsuarioRegistroAtividade.dataInicial)) {
      parametrosAdicionais += `&DataInicial=${filtroUsuarioRegistroAtividade.dataInicial}`;
    }

    if (UtilitarioGeral.valorValido(filtroUsuarioRegistroAtividade.dataFinal)) {
      parametrosAdicionais += `&DataFinal=${filtroUsuarioRegistroAtividade.dataFinal}`;
    }

    if (filtroUsuarioRegistroAtividade.ordenacao !== undefined) {
      filtroUsuarioRegistroAtividade.ordenacao.forEach((item) => {
        parametrosAdicionais += `&Ordenacao=${item}`;
      });
    }

    const result: any = await this.apiSistema.get(`${this.endPoint}/registro-atividades${parametrosAdicionais}`);
    return result.data;
  }

  public async adicionarAtalho(identificadorRecurso: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/adicionar-atalho/${identificadorRecurso}`);
    return result.data;
  }

  public async removerAtalho(identificadorRecurso: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/remover-atalho/${identificadorRecurso}`);
    return result.data;
  }

  public async obterMeusAtalhos(): Promise<IDTOMeusAtalhos[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/meus-atalhos`);
    return result.data;
  }

  public async obterMenu(): Promise<IMenu[]> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/menu`);
    return result.data;
  }

  public async salvamentoAutomaticoApi(objeto: ISalvamentoAutomatico): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`${this.endPoint}/salvamento-automatico`, objeto);
    return result.data;
  }

  public async obterUltimoSalvamentoAutomaticoApi(codigoEmpresa: number, codigoUsuario: number, identificador: string): Promise<ISalvamentoAutomatico> {
    const result: any = await this.apiSistema.get(`${this.endPoint}/salvamento-automatico/empresa/${codigoEmpresa}/usuario/${codigoUsuario}/identificador/${identificador}`);
    return result.data;
  }

  public async removerSalvamentoAutomaticoApi(codigoEmpresa: number, codigoUsuario: number, identificador: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/salvamento-automatico/empresa/${codigoEmpresa}/usuario/${codigoUsuario}/identificador/${identificador}`);
    return result.data;
  }

  public async salvamentoAutomaticoLocalStorage(identificador: string, objeto: ISalvamentoAutomatico): Promise<void> {
    salvarItem(identificador, JSON.stringify(objeto));
  }

  public async obterSalvamentoAutomaticoLocalStorage(identificador: string): Promise<ISalvamentoAutomatico> {
    const salvamentoAutomaticoLocalStorage = obterItem(identificador) as ISalvamentoAutomatico;

    if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
      return salvamentoAutomaticoLocalStorage;
    }
    return {} as ISalvamentoAutomatico;
  }

  public obterLocalStorage(chave: string): string {
    const valor = obterItem(chave);

    if (UtilitarioGeral.valorValido(String(valor))) {
      return String(valor);
    }
    return '';
  }

  public salvarLocalStorage(chave: string, valor: string): void {
    salvarItem(chave, valor);
  }

  public obterSalvamentoMaisRecente(salvamentoAutomaticoApi: ISalvamentoAutomatico, salvamentoAutomaticoLocalStorage: ISalvamentoAutomatico): ISalvamentoAutomatico {
    if (UtilitarioGeral.objetoValido(salvamentoAutomaticoApi) && UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
      if (UtilitarioData.validaDataPreenchida(salvamentoAutomaticoApi.data) && UtilitarioData.validaDataPreenchida(salvamentoAutomaticoLocalStorage.data)) {
        if (salvamentoAutomaticoApi.data === salvamentoAutomaticoLocalStorage.data) {
          return salvamentoAutomaticoApi;
        }
        if (UtilitarioData.verificaDataMaisRecente(salvamentoAutomaticoApi.data, salvamentoAutomaticoLocalStorage.data)) {
          return salvamentoAutomaticoApi;
        } if (UtilitarioData.verificaDataMaisRecente(salvamentoAutomaticoLocalStorage.data, salvamentoAutomaticoApi.data)) {
          return salvamentoAutomaticoLocalStorage;
        }
      }
    }

    if (UtilitarioGeral.objetoValido(salvamentoAutomaticoApi) && !UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
      return salvamentoAutomaticoApi;
    }

    if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage) && !UtilitarioGeral.objetoValido(salvamentoAutomaticoApi)) {
      return salvamentoAutomaticoLocalStorage;
    }

    return {} as ISalvamentoAutomatico;
  }

  public removerSalvamentoAutomaticoLocalStorage(identificador: string): void {
    removerItem(identificador);
  }

  public async preencherDadosSistema(): Promise<void> {
    const contratantesDisponiveis = await this.obterDadosContratantes();
    await storeSistema.mutations.atualizarDadosContratantes(contratantesDisponiveis);
    const dadosUsuarioAutenticado = await this.obterDadosUsuarioAutenticado();
    storeSistema.mutations.atualizarDadosUsuarioAutenticado(dadosUsuarioAutenticado);
    const meusAtalhos = await this.obterMeusAtalhos();
    storeSistema.mutations.atualizarMeusAtalhos(meusAtalhos);
    const menus = await this.obterMenu();
    storeSistema.mutations.atualizarMenus(menus);
  }

  public obtemVariavelPorTexto(textoVariavel: string): string {
    let variavel = '';
    if (UtilitarioGeral.valorValido(textoVariavel)) {
      variavel = textoVariavel.toLocaleUpperCase();
      variavel = variavel.replace(' ', '_');
      variavel = `[{${variavel}}]`;
    }
    return variavel;
  }

  public montaTextoComVariavelConteudo(texto: string, textoVariavel: string): string {
    let retorno = '';
    if (UtilitarioGeral.valorValido(texto)) {
      retorno = texto;
    }

    retorno += this.obtemVariavelPorTexto(textoVariavel);
    return retorno;
  }

  public async gerarLinkArquivoPrivado(pasta: string, nomeArquivo: string): Promise<IRetornoArquivo> {
    const arquivo: IArquivo = {} as IArquivo;
    arquivo.pasta = pasta;
    arquivo.nome = nomeArquivo;
    const result: any = await this.apiSistema.post(`${this.endPoint}/arquivos/link-privado`, arquivo);
    return result.data;
  }

  public async enviarArquivoUnicoPublico(relacaoArquivo: ERelacaoArquivo, arquivo: any): Promise<IRetornoArquivo> {
    const data = new FormData();
    data.append('arquivos', arquivo);

    const result: any = await this.apiSistema.upload(`${this.endPoint}/arquivos/publicos/relacao-arquivo/${relacaoArquivo}`, data);
    return result.data[0];
  }

  public async enviarArquivosPublicos(empresa: number, relacaoArquivo: ERelacaoArquivo, arquivos: any[]): Promise<IRetornoArquivo[]> {
    const data = new FormData();
    for (let i = 0; i < arquivos.length; (i += 1)) {
      data.append('arquivos', arquivos[i]);
    }

    const result: any = await this.apiSistema.upload(`${this.endPoint}/arquivos/publicos/relacao-arquivo/${relacaoArquivo}/empresa/${empresa}`, data);
    return result.data;
  }

  public async enviarArquivoUnicoPrivado(empresa: number, relacaoArquivo: ERelacaoArquivo, arquivo: any): Promise<IRetornoArquivo> {
    const data = new FormData();
    data.append('arquivos', arquivo);

    const result: any = await this.apiSistema.upload(`${this.endPoint}/arquivos/privados/relacao-arquivo/${relacaoArquivo}/empresa/${empresa}`, data);
    return result.data[0];
  }

  public async enviarArquivosPrivados(empresa: number, relacaoArquivo: ERelacaoArquivo, arquivos: any[]): Promise<IRetornoArquivo[]> {
    const data = new FormData();
    for (let i = 0; i < arquivos.length; (i += 1)) {
      data.append('arquivos', arquivos[i]);
    }

    const result: any = await this.apiSistema.upload(`${this.endPoint}/arquivos/privados/relacao-arquivo/${relacaoArquivo}/empresa/${empresa}`, data);
    return result.data;
  }

  public async removerArquivoPorUrl(url: string): Promise<IRetornoArquivo> {
    const result: any = await this.apiSistema.delete(`${this.endPoint}/arquivos`, url);
    return result.data;
  }

  public async enviarEmailRedefinicaoSenha(email: string, empresa: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`autenticacao/email-redefinicao-senha/empresa/${empresa}`, email);
    return result.data;
  }

  public async validarRedefinicaoSenha(codigoAcesso: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.get(`autenticacao/validar-redefinicao-senha/${codigoAcesso}`);
    return result.data;
  }

  public async redefinirSenha(codigoAcesso: string, senhaNova: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiSistema.post(`autenticacao/redefinir-senha/${codigoAcesso}`, senhaNova);
    return result.data;
  }
}
export default ServicoSistema;
